import React, { useEffect, useState, useContext } from 'react'
import IAddress from '../../server/src/Interfaces/IAddress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import IFireplace from '../../server/src/Interfaces/IFireplace'
import ky from 'ky'
import IAddressCategory from '../../server/src/Interfaces/IAddressCategory'
import IAddressType from '../../server/src/Interfaces/IAddressType'
import toast from 'react-hot-toast'
import { GlobalContext } from '../App'
import { updateFireplaces, updateAddressCategories, updateAddressTypes } from '../Modules/Data'
import AddressTasks from '../Tasks/AddressTasks'
import { API_URL } from '../Globals'

const EditCustomer: React.FC<{ selectedAddress: IAddress, clearSelection: () => void, updateAddressListFn?: () => void }> = ({ selectedAddress, clearSelection, updateAddressListFn }) => {
    const { globalVariable } = useContext(GlobalContext)
    const [selectedAddressForm, setSelectedAddressForm] = useState<IAddress>(selectedAddress)
    const [fireplaceIds, setFireplaceIds] = useState<IFireplace[]>([])
    const [addressCategories, setAddressCategories] = useState<IAddressCategory[]>([])
    const [addressTypes, setAddressTypes] = useState<IAddressType[]>([])

    useEffect(() => {
        getInitData(globalVariable.sessionToken)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    useEffect(() => {
        setSelectedAddressForm(selectedAddress)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fireplaceIds, addressCategories, addressTypes])

    if (!selectedAddress) {
        return (
            <div className="p-4 sm:p-8 bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-200 rounded-lg shadow-lg">
                <h2 className="text-xl text-blue-800 mb-6">Kohdetta ei valittuna</h2>
            </div>
        )
    }

    const getInitData = async (sessionToken: any) => {
        updateFireplaces(sessionToken, setFireplaceIds)
        updateAddressCategories(sessionToken, setAddressCategories)
        updateAddressTypes(sessionToken, setAddressTypes)
    }

    const updateAddress = (id: string) => {
        ky.post(API_URL + '/api/address/update/' + id, {
            json: selectedAddressForm,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + globalVariable.sessionToken,
                'Token': localStorage.getItem('token') as string
            }
        }).then((data) => {
            if (data.ok) {
                clearSelection()
                if (updateAddressListFn) {
                    updateAddressListFn()
                }
                toast.success('Päivitetty')
            }
        }).catch((error) => {
            console.log(error)
            toast.error('Tallennusvirhe!', { duration: 10000 })
        })
    }

    const setNextSweepDate = (years: number) => {
        const lastSweepDate = new Date(selectedAddressForm.lastSweepDate);
        lastSweepDate.setFullYear(lastSweepDate.getFullYear() + years);
        setSelectedAddressForm({
            ...selectedAddressForm,
            nextSweepDate: lastSweepDate.toISOString().split('T')[0]
        });
    }

    return (
        <div className="container mx-auto mb-10">
            <div className="p-6 sm:p-10 bg-gradient-to-r from-blue-100 to-indigo-100 border border-blue-300 rounded-xl shadow-2xl">
                <h2 className="text-2xl font-semibold text-blue-900 mb-8">Muokkaa kohdetta: {selectedAddressForm.address}, {selectedAddressForm.zip} {selectedAddressForm.city}</h2>
                <div className="space-y-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                        <div>
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-2">Etunimi</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, firstname: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.firstname} />
                        </div>
                        <div>
                            <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-2">Sukunimi</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, lastname: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.lastname} />
                        </div>
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-2">Osoite</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, address: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.address} />
                        </div>
                        <div>
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700 mb-2">Postinumero</label>
                            <input
                                type="text"
                                id="zip"
                                name="zip"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, zip: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.zip} />
                        </div>
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-2">Paikkakunta</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, city: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.city} />
                        </div>
                        <div>
                            <label htmlFor="lastSweepDate" className="block text-sm font-medium text-gray-700 mb-2">Viimeisin nuohous</label>
                            <input
                                type="date"
                                id="lastSweepDate"
                                name="lastSweepDate"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, lastSweepDate: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                value={selectedAddressForm.lastSweepDate} />
                        </div>
                        <div>
                            <label htmlFor="nextSweepingDate" className="block text-sm font-medium text-gray-700 mb-2">Seuraava nuohous</label>
                            <input
                                type="date"
                                id="nextSweepingDate"
                                name="nextSweepingDate"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, nextSweepDate: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                value={selectedAddressForm.nextSweepDate} />
                        </div>
                        <div>
                            <label htmlFor="nextSweepingDate" className="block text-sm font-medium text-gray-700 mb-2">Pikanäppäimet (Seuraava nuohous)</label>
                            <div className="flex space-x-2">
                                <button
                                    className="flex-1 px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out relative group"
                                    type="button"
                                    onClick={() => setNextSweepDate(1)}
                                >
                                    1 Vuosi
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-3 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none w-64">
                                        Asettaa seuraavan nuohouksen 1 vuoden päähän (viimeisin nuohous on oltava määritelty)
                                    </div>
                                </button>
                                <button
                                    className="flex-1 px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out relative group"
                                    type="button"
                                    onClick={() => setNextSweepDate(2)}
                                >
                                    2 Vuosi
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-3 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none w-64">
                                        Asettaa seuraavan nuohouksen 2 vuoden päähän (viimeisin nuohous on oltava määritelty)
                                    </div>
                                </button>
                                <button
                                    className="flex-1 px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out relative group"
                                    type="button"
                                    onClick={() => setNextSweepDate(3)}
                                >
                                    3 Vuosi
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-3 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none w-64">
                                        Asettaa seuraavan nuohouksen 3 vuoden päähän (viimeisin nuohous on oltava määritelty)
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Puhelinnumerot</label>
                            {selectedAddressForm.phoneNumbers.map((phoneNumber, index) => (
                                <div key={index} className="flex items-center mb-3">
                                    <input
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            const newPhoneNumbers = [...selectedAddressForm.phoneNumbers]
                                            newPhoneNumbers[index] = e.target.value
                                            setSelectedAddressForm({ ...selectedAddressForm, phoneNumbers: newPhoneNumbers })
                                        }}
                                        className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newPhoneNumbers = selectedAddressForm.phoneNumbers.filter((_, i) => i !== index)
                                            setSelectedAddressForm({ ...selectedAddressForm, phoneNumbers: newPhoneNumbers })
                                        }}
                                        className="ml-3 px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200 ease-in-out"
                                    >
                                        <span className='whitespace-nowrap'><FontAwesomeIcon icon={faTrash} /> Poista</span>
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    const newPhoneNumbers = [...selectedAddressForm.phoneNumbers, '']
                                    setSelectedAddressForm({ ...selectedAddressForm, phoneNumbers: newPhoneNumbers })
                                }}
                                className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200 ease-in-out"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Lisää puhelinnumero
                            </button>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700 mb-2">Lisätiedot</label>
                            <textarea
                                id="additionalInfo"
                                name="additionalInfo"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, additionalInfo: e.target.value })}
                                rows={4}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                defaultValue={selectedAddressForm.additionalInfo}
                            ></textarea>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Tulisijat</label>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                {fireplaceIds.map((fireplace: IFireplace) => (
                                    <div key={fireplace._id} className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id={`fireplace-${fireplace.id}`}
                                            name="fireplaces"
                                            value={fireplace._id}
                                            onChange={(e) => {
                                                const newSelectedFireplaceIds = [...selectedAddressForm.selectedFireplaceIds]
                                                if (e.target.checked) {
                                                    newSelectedFireplaceIds.push(fireplace.id)
                                                } else {
                                                    newSelectedFireplaceIds.splice(newSelectedFireplaceIds.indexOf(fireplace.id), 1)
                                                }
                                                setSelectedAddressForm({ ...selectedAddressForm, selectedFireplaceIds: newSelectedFireplaceIds })
                                            }}
                                            className="form-checkbox h-5 w-5 text-blue-600 transition duration-200 ease-in-out rounded border-gray-300 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            checked={selectedAddressForm.selectedFireplaceIds?.includes(fireplace.id)}
                                        />
                                        <label htmlFor={`fireplace-${fireplace._id}`} className="ml-3 block text-sm font-medium text-gray-700 select-none">
                                            {fireplace.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="addressType" className="block text-sm font-medium text-gray-700 mb-2">Osoitetyyppi</label>
                            <select
                                id="addressType"
                                name="addressType"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, addressType: parseInt(e.target.value) })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                value={selectedAddressForm.addressType}
                            >
                                {addressTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="addressCategoryId" className="block text-sm font-medium text-gray-700 mb-2">Osoitekategoria</label>
                            <select
                                id="addressCategoryId"
                                name="addressCategoryId"
                                onChange={(e) => setSelectedAddressForm({ ...selectedAddressForm, addressCategoryId: parseInt(e.target.value) })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                value={selectedAddressForm.addressCategoryId}
                            >
                                {addressCategories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="sm:col-span-2">
                            <AddressTasks addressId={selectedAddress._id} />
                        </div>
                    </div>
                    <div className="mt-10 flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4">
                        <button
                            onClick={() => updateAddress(selectedAddressForm._id.toString())}
                            type="button"
                            className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ease-in-out"
                        >
                            Tallenna
                        </button>
                        <button
                            type="button"
                            onClick={clearSelection}
                            className="w-full sm:w-auto px-6 py-3 bg-red-600 text-white font-medium rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-200 ease-in-out"
                        >
                            Poistu
                        </button>
                    </div>
                </div>
                <p className="text-sm text-gray-700 mt-4">
                    Tunniste: <i>{selectedAddress._id.toString()}</i>
                </p>
            </div>
        </div>
    )
}

export default EditCustomer